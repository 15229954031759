.card, .card-footer, .card-header {
	// width: calc(100% - 1.25em);
	width: 100%;
	background: none;
	border: none;
	border-radius: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	a {
		&:hover {
			text-decoration: none;
		}
	}
}
.card {
	// width: calc(100% - 1.25em);
	// margin: 0 0.625em 2.5em 0.625em;
	// margin: 1.25em 0;
	.card-header {
		h5, .entry-teaser-headline {
			font-weight: 100;
			margin-bottom: 0.25em;
		}
		padding: 0;
		border-radius: 0;
	}
	.card-body {
		padding: 0.625em 0 0 0;
		font-size: 0.9375em;
		// line-height: 1.75;
	}
	.card-footer {
		border-radius: 0;
		padding: 0.9375em 0 0.3125em 0;
	}
}
.card-img-top, .card-img {
	border-radius: 0;
}

