.embed-responsive {
	overflow:hidden;
	//padding-bottom:56.25%;
	position:relative;
	height:0;
}
.embed-responsive iframe {
	left:0;
	top:0;
	height:100%;
	width:100%;
	position:absolute;
}
.embed-responsive-16by9 {
	padding-bottom: 54.25%;
}
.embed-responsive-16by4 {
	padding-bottom: 25%;
}
.embed-responsive-16by6 {
	padding-bottom: 37.5%;
}
.embed-responsive-3by4 {
	&::before {
		padding-top: percentage(4 / 3);
	}
	padding-bottom: percentage(4 / 3);
}
.embed-responsive-4by3 {
	&::before {
		padding-top: percentage(3 / 4);
	}
	padding-bottom: percentage(3 / 4);
}

admin-bar {
	z-index: 1000 !important;
	display: none;
 &.sticky {
		position: relative !important;
	}
}
