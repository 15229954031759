header {
	display: block;
	position: absolute;
	z-index: 4;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	.header-top-container {
		position: relative;
		background: none;
		@media (min-width: 991.9px) {
			.row {
				display: flex;
				justify-content: center;
				align-items: flex-start;
				@media (max-width: 991.9px) {
					align-items: flex-start;
				}
			}
		}
		.header-logo-container {
			margin: 0;
			padding: 0 2.5rem;
			// @media (max-width: 1199.9px) {
			// 	padding: 0 2.5rem;
			// }
			// @media (max-width: 767.9px) {
			// 	padding: 0 1.875rem;
			// }
			@media (max-width: 575.9px) {
				padding: 0 1.875rem;
			}
			.header-logo-inner-container {
				display: inline-block;
				padding: 1.625rem 1.625rem;
				// padding-left: clamp(0.625em, calc(0.625em + 1.0 * ((100vw - 62em) / 13)), 1.625em);
				// padding-right: clamp(0.625em, calc(0.625em + 1.0 * ((100vw - 62em) / 13)), 1.625em);
				background-color: $gray-dark2;
			}
			.header-logo {
				display: inline-block;
				background: center center/contain no-repeat;
				text-indent: -9999px;
				margin: 0;
				width: 13.875rem;
				height: 6.875rem;
				@media (max-width: 1199.9px) {
					width: 225px;
					height: 112px;
				}
				@media (max-width: 991.9px) {
					width: 200px;
					height: 100px;
				}
				@media (max-width: 767.9px) {
					margin: 0.625em 0em;
					width: 180px;
					height: 90px;
				}
				@media (max-width: 575.9px) {
					width: 125px;
					height: 62px;
				}
			}
		}
		.header-info-container {
			display: inline-block;
			width: auto;
			.row {
				.col-6 {
					width: auto;
				}
			}
			b {
				font-family: $font-nav1;
				font-size: 1.125em;
				text-transform: uppercase;
				color: $black;
				white-space: nowrap;
			}
			a {
				color: $gray-dark1;
				&:hover {
					color: $brandcolor1;
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $black;
					text-decoration: none;
				}
			}
		}
	}
	.header-nav-container {
		margin: 0;
		padding: 0;
		background: none;
		.navbar {
			padding: 0;
		}
	}
}
