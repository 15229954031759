//Support Main Page
#support-plan-headline-small {
	.block-headline-text {
		font-family: $font-heading2;
		text-transform: uppercase;
	}
}

#support-plan-headline-large {
	.block-headline-text {
		color: $brandcolor1 !important;
		font-family: $font-heading2;
		font-weight: 900;
		text-transform: uppercase;
		letter-spacing: 0.125em;
	}
}

#support-plan-pricing-chart {
	.block-inner-container {
		.image-callout-card {
			height: 100%;
			padding: 1.25rem 0.625rem;
			background-color: $gray-dark2;
			border-radius: 0 2.5em;
			border: 4px solid $brandcolor1;
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 1.0rem;
				right: 1.0rem;
				width: 3.0rem;
				height: 3.0rem;
				background-image: url('/assets/images/Branding/iq-logo-symbol-only-dark.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			}
			.card-body {
				margin: 0;
				padding: 0 0.625rem;
				height: 100%;
				background-color: $gray-dark2;
				border-radius: 0;
				.card-headline-container {
					.image-callout-headline {
						color: $brandcolor1 !important;
						text-transform: uppercase;
						margin-bottom: 1.25rem;
					}
				}
				.image-callout-body-copy {
					color: $white;
					ul {
						li {
							font-size: 1.125rem;
							&::marker {
								color: $brandcolor1 !important;
							}
						}
					}
				}
			}
			.card-footer {
				padding: 0 0.625rem;
				background-color: $gray-dark2;
				border-radius: 0;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-end;
				.image-callout-citation {
					color: $brandcolor1 !important;
					font-family: $font-heading2;
					font-weight: 700;
					font-size: 1.75rem;
					font-style: normal;
				}
			}
		}
		.block-anim-container {
			.block-anim {
				padding: 0 0 1.25rem 0;
				height: 100%;
			}
		}
	}
}