#about-team-upper-staff {
	.card {
		border-radius: 0;
		&:hover {
			.img-zoom-hover {
				img {
					transform: none !important;
				}
			}
		}
		.card-img {
			border-radius: 0;
			.img-zoom-hover {
				border: none;
			}
		}
		.card-body {
			padding-top: 1.25rem;
			border-radius: 0;
			.card-headline-container {
				.image-callout-headline {
					margin-bottom: 0;
				}
			}
		}
		.card-footer {
			display: none;
			border-radius: 0;
		}
	}
}

#about-team-lower-staff {
	.card {
		.card-img-top {

		}
		.card-body {
			margin: 0;
			padding: 0.625rem;
			.image-icon-headline {
				margin: 0;
				padding: 0;
				color: $black;
				h4 {
					margin-bottom: 0;
				}
			}
			.image-icon-text {
				margin: 0;
				p {
					@extend h5;
					margin: -0.3125rem;
				}
			}
		}
	}
}