/* Main Projects Page */
.project-cards-outer-container {
	margin: 0;
	padding: 2.5rem 3.125rem 5.0rem 3.125rem;
	.project-card-container {
		padding: 0 0.625rem;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
}

// Project Teaser Cards
.project-card {
	width: calc(100% - 2.5em);
	margin: 0 0 5.0rem 0;
	&:hover {
		.project-card-image-container {
			.img-zoom-hover {
				img {
					transform: scale(1.25);
				}
			}
		}
	}
	@media (max-width: 767.9px) {
		margin: 0 0 7.5rem 0;
	}
	.project-card-image-container {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		overflow: hidden;
		@media (max-width: 767.9px) {
			justify-content: center;
		}
		.img-zoom-hover {	
		}
	}
	.project-card-text-container {
		&.project-card-text-container-with-image {
			padding: 0 0 0 1.25rem;
			@media (max-width: 767.9px) {
				padding: 1.875rem 0 0 0;
			}
		}
		.card-body {
			margin: 0;
			padding: 0;
			width: 100%;
			height: 100%;
			font-size: 1.0rem;
			line-height: 1.25rem;
			.project-teaser-headline {
				.card-title {
					margin: 0 0 0.625rem 0;
				}
			}
			.project-number, .project-client, .project-owner, .project-location {
				display: block;
				margin: 0 0 0.3125rem 0;
				padding: 0 0 0.3125rem 0;
				border-bottom: 1px dotted $gray-light2;
			}
			.project-teaser-copy {
				margin: 0.625rem 0 0 0;
			}
		}
	}
}

/* Project Detail Page */
.project-detail-container {
	.project-overview-container {
		margin: 0;
		padding: 5.0rem 2.5rem 5.0rem 2.5rem;
		.project-overview-body-container {
			.project-overview-body-headline-container {
				margin-bottom: 2.5rem;
			}
		}
		.project-overview-lists-container {
			column-count: 2;
			column-gap: 2.5em;
			.project-overview-single-list-container {
				break-inside: avoid-column;
				// margin-bottom: 0.625rem;
				.project-overview-list-header {
					font-size: 0.9375rem;
					font-weight: 700;
					line-height: 1;
					letter-spacing: 0.05rem;
					color: $brandcolor1;
					text-transform: uppercase;
				}
			}
			@media (max-width: 991.98px) {
				column-count: 1;
			}
			@media (max-width: 575.98px) {
				column-count: 2;
				padding-top: 1.25rem;
			}
			@media (max-width: 459.98px) {
				column-count: 1;
			}
		}
	}

	.header-image-slider-full-width {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $black;
		.carousel-overlay {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;
			background: linear-gradient(180deg, rgba($black,0.60) 0%, rgba($black,0) 30%, rgba($black,0) 100%);
			z-index: 1;
			pointer-events: none;
		}
		.image-slider {
			&.loading {
				//min-height: 60vh;
			}
			.image-slider-wrapper {
				.image-slide {
					.image-slide-image {
						//max-height: 60vh;
					}
				}
			}
		}
	}
}

//Project Callouts Block
.block-container-projects-callout {
	.block-inner-container {
		.block-projects-callout-row {
			.block-projects-callout-container {
				.project-card {
				}
			}
		}
	}
}