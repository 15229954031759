.block-anim-container {
	.block-anim {
		&.move-left, &.move-right, &.move-up, &.move-down {
			opacity: 0;
			-webkit-transition: opacity 1.0s linear, transform 1.5s ease-out;
			-moz-transition: opacity 1.0s linear, transform 1.5s ease-out;
			-ms-transition: opacity 1.0s linear, transform 1.5s ease-out;
			-o-transition: opacity 1.0s linear, transform 1.5s ease-out;
			transition: opacity 1.0s linear, transform 1.5s ease-out;
			&.move-left {
				transform: translateX(2.5rem);
			}
			&.move-right {
				transform: translateX(-2.5rem);
			}
			&.move-up {
				transform: translateY(2.5rem);
			}
			&.move-down {
				transform: translateY(-2.5rem);
			}
			&.active {
				transform: translate(0,0);
				opacity: 1;
			}
		}
		&.fade-in, &.fade-left, &.fade-right, &.fade-up, &.fade-down {
			opacity: 0;
			filter: blur(40px);
			-webkit-transition: opacity 2.0s ease-out, transform 1.5s ease-out, filter 1.0s ease-out;
			-moz-transition: opacity 2.0s ease-out, transform 1.5s ease-out, filter 1.0s ease-out;
			-ms-transition: opacity 2.0s ease-out, transform 1.5s ease-out, filter 1.0s ease-out;
			-o-transition: opacity 2.0s ease-out, transform 1.5s ease-out, filter 1.0s ease-out;
			transition: opacity 2.0s ease-out, transform 1.5s ease-out, filter 1.0s ease-out;
			&.fade-left {
				transform: translateX(5.0rem);
			}
			&.fade-right {
				transform: translateX(-5.0rem);
			}
			&.fade-up {
				transform: translateY(5.0rem);
			}
			&.fade-down {
				transform: translateY(-5.0rem);
			}
			&.active {
				transform: translate(0,0);
				opacity: 1;
				filter: blur(0);
			}
		}
	}
}

.banner-anim-container {
	.block-anim {
		display: block;
		&.fade-left {
			-webkit-animation: banner-anim-fade-up 1.25s;
			-moz-animation: banner-anim-fade-up 1.25s;
			-o-animation: banner-anim-fade-up 1.25s;
			-ms-animation: banner-anim-fade-up 1.25s;
			animation: banner-anim-fade-up 1.25s;
		}
		&.fade-right {
			-webkit-animation: banner-anim-fade-up 1.25s;
			-moz-animation: banner-anim-fade-up 1.25s;
			-o-animation: banner-anim-fade-up 1.25s;
			-ms-animation: banner-anim-fade-up 1.25s;
			animation: banner-anim-fade-up 1.25s;
		}
		&.fade-up {
			-webkit-animation: banner-anim-fade-up 1.25s;
			-moz-animation: banner-anim-fade-up 1.25s;
			-o-animation: banner-anim-fade-up 1.25s;
			-ms-animation: banner-anim-fade-up 1.25s;
			animation: banner-anim-fade-up 1.25s;
		}
		&.fade-down {
			-webkit-animation: banner-anim-fade-up 1.25s;
			-moz-animation: banner-anim-fade-up 1.25s;
			-o-animation: banner-anim-fade-up 1.25s;
			-ms-animation: banner-anim-fade-up 1.25s;
			animation: banner-anim-fade-up 1.25s;
		}
	}
}

@keyframes slide-left {
	from {
		left: 0%;
		opacity: 0;
	}
	to {
		left: -100%;
		opacity: 1;
	}
}

@keyframes slide-right {
	from {
		left: 100%;
		opacity: 0;
	}
	to {
		left: 0%;
		opacity: 1;
	}
}

@keyframes banner-anim-fade-up {
	0% {
		transform: translateY(5.0rem);
		opacity: 0;
		filter: blur(40px);
	}
	100% {
		transform: translateY(0);
		opacity: 1;
		filter: blur(0);
	}
}

@-webkit-keyframes banner-anim-fade-up {
	0% {
		transform: translateY(5.0rem);
		opacity: 0;
		filter: blur(40px);
	}
	100% {
		transform: translateY(0);
		opacity: 1;
		filter: blur(0);
	}
}
@-moz-keyframes banner-anim-fade-up {
	0% {
		transform: translateY(5.0rem);
		opacity: 0;
		filter: blur(40px);
	}
	100% {
		transform: translateY(0);
		opacity: 1;
		filter: blur(0);
	}
}
@-o-keyframes banner-anim-fade-up {
	0% {
		transform: translateY(5.0rem);
		opacity: 0;
		filter: blur(40px);
	}
	100% {
		transform: translateY(0);
		opacity: 1;
		filter: blur(0);
	}
}
@-ms-keyframes banner-anim-fade-up {
	0% {
		transform: translateY(5.0rem);
		opacity: 0;
		filter: blur(40px);
	}
	100% {
		transform: translateY(0);
		opacity: 1;
		filter: blur(0);
	}
}

::view-transition-group(root) {
	animation-duration: 1s;
}

::view-transition-old(root) {
	// animation-name: slide-right;
	// animation-duration: 2s;
}

::view-transition-new(root) {
	// animation-name: slide-left;
	// animation-duration: 2s;
}

::view-transition-old(video) {
	/* Don't show the frozen old view */
	display: none;
}

::view-transition-new(video) {
	/* Don't fade the new view in */
	animation: none;
}