.block-scroll-snap-group {
	position: relative;
	width: 100%;
	height: 100vh;
	@supports (height:100dvh) {
		height: 100dvh;
	}
	left: 0;
	overflow: hidden;
	> .block-scroll-snap-center {
		// position: absolute;
		position: relative;
		// width: 100%;
		// height: 100vh;
		// @supports (height:100dvh) {
		// 	height: 100dvh;
		// }
		// top: 0;
		// left: 0;
		transition: top 400ms linear;
		top: -0vh;
		&:first-child {
		}
	}
}
