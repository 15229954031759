//Brands Callout Cards Large
#brands-cards-large {
	.block-inner-container {
		.image-callout-card-container {
			height: 100%;
			.block-anim-container {
				height: 100%;
				.block-anim {
					height: 100%;
					.card {
						border-radius: 0;
						height: 100%;
						&:hover {
							.card-img {
								.img-zoom-hover {
									opacity: 1;
									filter: saturate(100%);
									img {
										transform: none !important;
									}
								}
							}
						}
						.card-img {
							display: flex;
							justify-content: center;
							align-items: center;
							border-radius: 0;
							min-height: 6.0rem;
							.img-zoom-hover {
								border: none;
								max-height: 6.0rem;
								max-width: 16.0rem;
								opacity: 0.75;
								filter: saturate(0%);
								-webkit-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
								-moz-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
								-ms-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
								-o-transition: ffilter 1.0s ease-out, opacity 1.0s ease-out;
								transition: filter 1.0s ease-out, opacity 1.0s ease-out;
							}
						}
						.card-body {
							margin: 0;
							padding: 1.25rem 1.5rem 0 1.5rem;
							border-radius: 0;
							.card-headline-container {
								.image-callout-headline {
									margin-bottom: 0;
								}
							}
						}
						.card-footer {
							border-radius: 0;
							margin: 0;
							padding: 0;
							.block-button-group-container {
								margin: 0;
								padding: 0 1.5rem;
								.btn::after {
									content: "";
									position: absolute;
									inset: 0;
									cursor: pointer!important;
									display: block;
									z-index: 99;
								}
							}
						}
					}
				}
			}
		}
	}
}

//Brands Image Icon Row Small
#brands-logos-small {
	.block-inner-container {
		.image-icon-container {
			height: 100%;
			.block-anim-container {
				height: 100%;
				.block-anim {
					height: 100%;
					.card {
						height: 100%;
						&:hover {
							.card-img-top {
								.image-icon-image {
									opacity: 1;
									filter: saturate(100%);
								}
							}
						}
						.card-img-top {
							min-height: 6.0rem;
							.image-icon-image {
								max-height: 6.0rem;
								max-width: 16.0rem;
								opacity: 0.75;
								filter: saturate(0%);
								-webkit-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
								-moz-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
								-ms-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
								-o-transition: ffilter 1.0s ease-out, opacity 1.0s ease-out;
								transition: filter 1.0s ease-out, opacity 1.0s ease-out;
							}
						}
						.card-body {
							margin: 0;
							padding: 0.625rem;
							.image-icon-headline {
								margin: 0;
								padding: 0;
								color: $black;
								h4 {
									margin-bottom: 0;
								}
							}
							.image-icon-text {
								margin: 0;
								p {
									//margin: -0.3125rem;
								}
							}
						}
					}
				}
			}
		}
	}
}