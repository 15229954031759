hr.divider-brand {
	border-top: 1px solid $brandcolor1;
	color: $brandcolor1;
}

hr.divider-gray {
	border-top: 1px solid $gray-light3;
	color: $gray-light3;
}

hr.divider-short {
	width: 6.25em;
}

hr.divider-left {
	margin: 0.625em 0 1.25em 0;
	@media (max-width: 575.9px) {
		margin: 0.625em auto 1.25em auto;
	}
}

hr.divider-block-intro {
	visibility: hidden;
	margin: 0.3125rem;
	padding: 0;
}

.block-border-left-brandcolor2 {
	border-left: 0.25rem solid $brandcolor1;
	padding-left: 1.25rem;
}

.block-border-right-brandcolor2 {
	border-right: 0.25rem solid $brandcolor1;
	padding-right: 1.25rem;
}
