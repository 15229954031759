.body, body { 
	font-family: $font-body1;
	font-weight: 400;
	font-style: normal;
	line-height: 1;
	color: $gray-dark2;
	text-rendering: auto;
}

.body {
	p, h1, h2, h3, h4, h5, h6 {
		overflow-wrap: break-word;
		-ms-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}
	p {
		font-size: 1.0rem;
		line-height: 1.5;
		font-weight: 400;
		color: $gray-dark2;
		text-wrap: pretty;
		font-size: 1.125rem;
		font-size: clamp(1.0rem, calc(1.0rem + 0.125 * ((100vw - 36rem) / 39)), 1.125rem);
		font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.125 * ((100vw - 36rem) / 39)), 1.125rem);
		font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.125 * ((100vw - 36rem) / 39)), 1.125rem);
		a {
			color: $brandcolor1;
			text-decoration: underline;
			&:hover {
				color: $brandcolor1-dark;
				text-decoration: underline;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $brandcolor1-dark;
				text-decoration: underline;
			}
		}
	}
	ul {
		display: inline-block;
		@supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {
			list-style: none; /* remove default bullets */
		}
		//list-style: none; /* remove default bullets */
		margin: 1.0rem 0 1.0rem 0.3125rem;
	}

	ul li::marker {
		content: "\f01b";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
		font-family: $font-icons2;
		color: $brandcolor1; /* Change the color */
		font-weight: 700; /* If you want it to be bold */
		// display: inline-block; /* Needed to add space between the bullet and the text */
		// width: 2.5rem;   Also needed for space (tweak if needed) 
		// margin-left: -2.5rem; /* Also needed for space (tweak if needed) */
		// transform: translateY(0.25rem);
		font-size: 1.0rem;
	}

	ul.list-bullet-target li::marker {
		content: "\f027";
	}
	ul.list-bullet-arrow li::marker {
		content: "\f022";
	}
	ul.list-bullet-checkmark li::marker {
		content: "\f021";
	}
	ul.list-bullet-question li::marker {
		content: "\f020";
	}
	ul.list-bullet-plus li::marker {
		content: "\f01f";
	}
	ul.list-bullet-marker li::marker {
		content: "\f01e";
	}
	ul.list-bullet-dot li::marker {
		content: "\f01c";
	}

	ul li {
		font-size: 1.125rem;
		font-size: clamp(1.0rem, calc(1.0rem + 0.125 * ((100vw - 36rem) / 39)), 1.125rem);
		font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.125 * ((100vw - 36rem) / 39)), 1.125rem);
		font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.125 * ((100vw - 36rem) / 39)), 1.125rem);
		line-height: 1.125;
		margin: 0 0 1.25rem -1.3125rem;
		padding-left: 0.625rem;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
	}

	ul li a {
		// font-weight: 400;
		// text-decoration: underline;
		// color: $black !important;
	}

	ul li a:hover {
		// color: $brandcolor1 !important;
	}

	ol li {
		line-height: 1.25;
		margin: 0.66rem 0;
	}

	ul.unstyled, ul.list-unstyled {
		list-style-type: none;
		margin: 0 0 2.5rem 0;
		padding: 0;
		li {
			margin: 0;
			padding: 0.625rem 0;
			line-height: 1;
			border-bottom: 1px solid $gray-dark2;
			&:first-child { 
				// padding-top: 0; 
			}
			&:last-child { 
				border-bottom: none; 
			}
			&::marker {
				content: "";
				display: none;
			}
			b, strong {
				color: $black;
				font-weight: 700;
			}
		}
	}

	ul.list-2column {
		// margin-top: 0;
		// padding-top: 0.9375rem;
		// border-top: 1px solid $brandcolor1;
		column-count: 2;
		column-gap: 4.0rem;
		// column-rule: 1px dotted $gray-white2;
		@include media-breakpoint-only(sm) {
			column-count: 1;
		}
		@include media-breakpoint-only(xs) {
			column-count: 1;
		}
	}
}

ul.list-lg {
	li {
		font-size: 1.25rem;
		font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.25 * ((100vw - 36rem) / 39)), 1.25rem);
		font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.25 * ((100vw - 36rem) / 39)), 1.25rem);
		font-size: clamp(1.0rem, calc(1.0rem + 0.25 * ((100vw - 36rem) / 39)), 1.25rem);
		&::marker {
			font-size: 1.25rem;
			font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.25 * ((100vw - 36rem) / 39)), 1.25rem);
			font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.25 * ((100vw - 36rem) / 39)), 1.25rem);
			font-size: clamp(1.0rem, calc(1.0rem + 0.25 * ((100vw - 36rem) / 39)), 1.25rem);
		}
	}
}

ul.list-sm {
	li {
		font-size: 0.875rem;
	}
}

ul.list-b {
	li {
		font-weight: 700;
	}
}


p.lead, .lead p {
	color: $black;
	font-family: $font-body1;
	font-weight: 400;
	line-height: 1.75;
	font-size: 1.375rem;
	font-size: clamp(1.125rem, -webkit-calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
	font-size: clamp(1.125rem, -moz-calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
	font-size: clamp(1.125rem, calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
	b, strong {
		color: $brandcolor1-light;
		font-weight: 500;
	}
}

p.lead-sm, .lead-sm p {
	color: $gray-mid3;
	line-height: 1.75;
	font-size: 1.25rem;
	font-size: clamp(1.125rem, -webkit-calc(1.125rem + 0.125 * ((100vw - 36rem) / 39)), 1.25rem);
	font-size: clamp(1.125rem, -moz-calc(1.125rem + 0.125 * ((100vw - 36rem) / 39)), 1.25rem);
	font-size: clamp(1.125rem, calc(1.125rem + 0.125 * ((100vw - 36rem) / 39)), 1.25rem);
}

p.text-small, .text-small p, .text-small {
	font-size: 0.875rem;
	line-height: 1.25;
}

p.text-xs, .text-xs p, .text-xs {
	font-size: 0.75rem;
	line-height: 1.125;
}

b, strong {
	color: $black;
	font-weight: 700;
}

a {
	color: $brandcolor1;
	text-decoration: none;
	&:hover {
		color: $brandcolor1-light;
		text-decoration: underline;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark;
		text-decoration: underline;
	}
}

h1, h2, h3, h4, h5, h6 {
	text-wrap: balance;
	// overflow-wrap: break-word;
	// hyphens: auto;
	// -ms-hyphens: auto;
	// -moz-hyphens: auto;
}

h1.display-1,
h1.display-2,
h1.display-3,
h1.display-4,
h1,
h2,
h3 {
	font-family: $font-heading1;
	line-height: 1.0;
	letter-spacing: -0.0125rem;
	b, strong {
		color: $brandcolor1;
		& .text-light {
			color: $brandcolor1-light;
		}
	}
}

h4,
h5,
h6 {
	font-family: $font-heading2;
	line-height: 1.0625;
	letter-spacing: 0;
	b, strong {
		color: $brandcolor1;
		& .text-light {
			color: $brandcolor1-light;
		}
	}
}

h1.display-1,
h1.display-2,
h1.display-3,
h1.display-4,
h1,
h2,
h3,
h4,
h5,
h6 {
	i {
		display: inline-block;
		margin-bottom: 1.0rem;
		padding: 0.75rem 1.875rem;
		color: $white;
		background-color: $gray-dark3;
		font-style: normal;
		b, strong {
			color: $brandcolor1;
			& .text-light {
				color: $brandcolor1;
			}
		}
	}
	// &.text-light {
	// 	i:not(.text-dark) {
	// 		color: $gray-dark2;
	// 		background-color: rgba($white, 0.9);
	// 		-webkit-backdrop-filter: saturate(180%) blur(8px);
	// 		-moz-backdrop-filter: saturate(180%) blur(8px);
	// 		-ms-backdrop-filter: saturate(180%) blur(8px);
	// 		-o-backdrop-filter: saturate(180%) blur(8px);
	// 		backdrop-filter: saturate(180%) blur(8px);
	// 		font-style: normal;
	// 		b, strong {
	// 			color: $brandcolor1;
	// 		}
	// 	}
	// }
}

// h1.display-1.text-light,
// h1.display-2.text-light,
// h1.display-3.text-light,
// h1.display-4.text-light,
// h1.text-light,
// h2.text-light,
// h3.text-light,
// h4.text-light,
// h5.text-light,
// h6.text-light {
// 	i {
// 		color: $gray-dark2;
// 		background-color: $white;
// 		font-style: normal;
// 		b, strong {
// 			color: $brandcolor1;
// 		}
// 	}
// }

h1 {
	font-weight: 700;
	color: $gray-dark2;
	font-size: 4.0rem;
	b, strong {
		font-weight: 700;
	}
	font-size: 4.5625rem;
	font-size: clamp(3.3125rem, -webkit-calc(3.3125rem + 1.25 * ((100vw - 62rem) / 39)), 4.5625rem);
	font-size: clamp(3.3125rem, -moz-calc(3.3125rem + 1.25 * ((100vw - 62rem) / 39)), 4.5625rem);
	font-size: clamp(3.3125rem, calc(3.3125rem + 1.25 * ((100vw - 62rem) / 39)), 4.5625rem);
	// @media (min-width: 991.9px) {
	// 	font-size: clamp(2.5rem, -webkit-calc(2.5rem + 1.5 * ((100vw - 62rem) / 39)), 4.0rem);
	// 	font-size: clamp(2.5rem, -moz-calc(2.5rem + 1.5 * ((100vw - 62rem) / 39)), 4.0rem);
	// 	font-size: clamp(2.5rem, calc(2.5rem + 1.5 * ((100vw - 62rem) / 39)), 4.0rem);
	// }
	// @media (max-width: 991.9px) {
	// 	font-size: clamp(2.25rem, -webkit-calc(2.25rem + 1.75 * ((100vw - 36rem) / 39)), 4.0rem);
	// 	font-size: clamp(2.25rem, -moz-calc(2.25rem + 1.75 * ((100vw - 36rem) / 39)), 4.0rem);
	// 	font-size: clamp(2.25rem, calc(2.25rem + 1.75 * ((100vw - 36rem) / 39)), 4.0rem);
	// }
}

h1.display-1 {
	font-weight: 700;
	color: $black;
	b, strong {
		font-weight: 700;
	}
	font-size: 5.375rem;
	font-size: clamp(3.625rem, -webkit-calc(3.625rem + 1.75 * ((100vw - 36rem) / 39)), 5.375rem);
	font-size: clamp(3.625rem, -moz-calc(3.625rem + 1.75 * ((100vw - 36rem) / 39)), 5.375rem);
	font-size: clamp(3.625rem, calc(3.625rem + 1.75 * ((100vw - 36rem) / 39)), 5.375rem);
	@media (max-width: 575.9px) {
		font-size: 3.125rem;
	}
	@media (max-width: 430.9px) {
		font-size: 2.625rem;
	}
}

h1.display-2 {
	font-weight: 700;
	font-style: normal;
	color: $gray-dark2;
	b, strong {
		font-weight: 700;
	}
	font-size: 5.375rem;
	font-size: clamp(3.625rem, -webkit-calc(3.625rem + 1.75 * ((100vw - 36rem) / 39)), 5.375rem);
	font-size: clamp(3.625rem, -moz-calc(3.625rem + 1.75 * ((100vw - 36rem) / 39)), 5.375rem);
	font-size: clamp(3.625rem, calc(3.625rem + 1.75 * ((100vw - 36rem) / 39)), 5.375rem);
	@media (max-width: 575.9px) {
		font-size: 3.125rem;
	}
	@media (max-width: 430.9px) {
		font-size: 2.625rem;
	}
}


h1.display-3 {
	font-weight: 500;
	color: $black;
	b, strong {
		font-weight: 500;
	}
	font-size: 4.875rem;
	font-size: clamp(3.125rem, -webkit-calc(3.125rem + 1.75 * ((100vw - 36rem) / 39)), 4.875rem);
	font-size: clamp(3.125rem, -moz-calc(3.125rem + 1.75 * ((100vw - 36rem) / 39)), 4.875rem);
	font-size: clamp(3.125rem, calc(3.125rem + 1.75 * ((100vw - 36rem) / 39)), 4.875rem);
	@media (max-width: 575.9px) {
		font-size: 2.625rem;
	}
	@media (max-width: 575.9px) {
		font-size: 2.125rem;
	}
}

h1.display-4 {
	font-weight: 500;
	font-style: normal;
	color: $gray-dark2;
	b, strong {
		font-weight: 500;
	}
	font-size: 4.875rem;
	font-size: clamp(3.125rem, -webkit-calc(3.125rem + 1.75 * ((100vw - 36rem) / 39)), 4.875rem);
	font-size: clamp(3.125rem, -moz-calc(3.125rem + 1.75 * ((100vw - 36rem) / 39)), 4.875rem);
	font-size: clamp(3.125rem, calc(3.125rem + 1.75 * ((100vw - 36rem) / 39)), 4.875rem);
	@media (max-width: 575.9px) {
		font-size: 2.625rem;
	}
	@media (max-width: 575.9px) {
		font-size: 2.125rem;
	}
}


h2 {
	font-weight: 700;
	font-style: normal;
	font-size: 3.0rem;
	color: $black;
	b, strong {
		font-weight: 700;
	}
	font-size: 4.0rem;
	font-size: clamp(2.75rem, -webkit-calc(2.75rem + 1.25 * ((100vw - 62rem) / 39)), 4.0rem);
	font-size: clamp(2.75rem, -moz-calc(2.75rem + 1.25 * ((100vw - 62rem) / 39)), 4.0rem);
	font-size: clamp(2.75rem, calc(2.75rem + 1.25 * ((100vw - 62rem) / 39)), 4.0rem);
}

h3 {
	font-weight: 700;
	margin-bottom: 0.625rem;
	color: $gray-dark2;
	b, strong {
		font-weight: 700;
	}
	font-size: 3.0rem;
	font-size: clamp(2.0rem, -webkit-calc(2.0rem + 1 * ((100vw - 36rem) / 39)), 3.0rem);
	font-size: clamp(2.0rem, -moz-calc(2.0rem + 1 * ((100vw - 36rem) / 39)), 3.0rem);
	font-size: clamp(2.0rem, calc(2.0rem + 1 * ((100vw - 36rem) / 39)), 3.0rem);
}

h4 {
	font-weight: 700;
	color: $black;
	b, strong {
		font-weight: 700;
	}
	font-size: 2.25rem;
	font-size: clamp(1.5rem, -webkit-calc(1.5rem + 0.75 * ((100vw - 36rem) / 39)), 2.25rem);
	font-size: clamp(1.5rem, -moz-calc(1.5rem + 0.75 * ((100vw - 36rem) / 39)), 2.25rem);
	font-size: clamp(1.5rem, calc(1.5rem + 0.75 * ((100vw - 36rem) / 39)), 2.25rem);
}

h5 {
	font-weight: 500;
	color: $gray-dark2;
	b, strong {
		font-weight: 500;
	}
	font-size: 1.75rem;
	font-size: clamp(1.25rem, -webkit-calc(1.25rem + 0.5 * ((100vw - 36rem) / 39)), 1.75rem);
	font-size: clamp(1.25rem, -moz-calc(1.25rem + 0.5 * ((100vw - 36rem) / 39)), 1.75rem);
	font-size: clamp(1.25rem, calc(1.25rem + 0.5 * ((100vw - 36rem) / 39)), 1.75rem);
}

h6 {
	font-weight: 700;
	text-transform: uppercase;
	color: $gray-dark2;
	letter-spacing: -.05rem;
	b, strong {
		font-weight: 700;
	}
	font-size: 1.25rem;
	font-size: clamp(1.125rem, -webkit-calc(1.125rem + 0.125 * ((100vw - 36rem) / 39)), 1.25rem);
	font-size: clamp(1.125rem, -moz-calc(1.125rem + 0.125 * ((100vw - 36rem) / 39)), 1.25rem);
	font-size: clamp(1.125rem, calc(1.125rem + 0.125 * ((100vw - 36rem) / 39)), 1.25rem);
}

.text-light {
	p, h1, h2, h3, h4, h5, h6, ul li::marker, ul li::before, a {
		color: $white;
	}
	p a {
		text-decoration: underline;
		&:hover {
			color: $white;
			text-decoration: underline;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $white;
			text-decoration: underline;
		}
	}
	label {
		color: $white !important;
	}
	b, strong {
		color: $brandcolor1-light;
	}
	p.lead, .lead p {
		b, strong {
			color: $brandcolor1-light;
		}
	}
}

h1.large-quote {
	vertical-align:bottom;
	font-size: 5.5rem;
	line-height: 1;
}

h2.large-quote {
	vertical-align:bottom;
	font-size: 4.5rem;
	line-height: 1;
}

h2.page-headline-text {
	margin-bottom: 0.625rem;
}

h4.page-subheadline-text {
	margin-top: 0.625rem;
}

h5.header-sm {
	font-size: 1.125rem;
}

h6.header-xs {
	font-size: 0.875rem;
	letter-spacing: 0.05rem;
}

ul.detail-list {
	list-style-type: none;
	margin: 0 0 2.5rem 0;
	padding: 0;
	color: $gray-mid2;
	border-top: 1px solid $gray-dark2;
	li {
		margin: 0;
		padding: 0.625rem 0;
		font-size: 0.8rem;
		line-height: 1.25;
		border-bottom: 1px solid $gray-white2;
		&:first-child { padding-top: 0.625rem }
		&:last-child { border-bottom: none; }
		&::marker {
			content: "";
			display: none;
		}
		b {
			color: $black;
			font-weight: 700;
		}
		a {
			display: block;
			position: relative;
			font-weight: 700;
			color: $black;
			&::after {
				content: "\f008";
				font-family: $font-icons2;
				display: inline-block;
				font-size: 0.625rem;
				font-weight: normal;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				text-rendering: auto;
				position: absolute;
				top: 0.375rem;
				right: 0.75rem;
				color: $gray-mid2;
			}
			&:hover {
				color: $brandcolor1;
				text-decoration: none;
				&::after {
					color: $brandcolor1;
				}
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $brandcolor1-dark;
				text-decoration: none;
				&::after {
					color: $brandcolor1-dark;
				}
			}
		}
		p {
			margin: 0;
		}
	}
}

.columns2 {
	column-count: 2;
	column-gap: 2.5rem;
	@include media-breakpoint-down(md) {
		column-count: 1;
		justify-self: center;
	}
	p {
		// break-inside: avoid-column;
	}
	ul {
		margin: 0 !important;
		li:first-child {
			margin-top: 0;
		}
	}
}

.columns3 {
	column-count: 3;
	column-gap: 2.5rem;
	@include media-breakpoint-down(lg) {
		column-count: 2;
	}
	@include media-breakpoint-down(md) {
		column-count: 1;
		justify-self: center;
	}
	p {
		// break-inside: avoid-column;
	}
	ul {
		margin: 0 !important;
		li:first-child {
			margin-top: 0;
		}
	}
}

