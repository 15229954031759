.page-title-banner-container {
	background-color: $gray-dark2;
	.page-title-full-width {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		-webkit-background-size: cover !important;
		-moz-background-size: cover !important;
		-o-background-size: cover !important;
		background-size: cover !important;
		background-repeat: no-repeat !important;
		background-position: center top;
		background-attachment: fixed;
		// filter: grayscale(100%);
		// -webkit-filter: grayscale(100%);
		// -moz-filter: grayscale(100%);
		// -o-filter: grayscale(100%);
		filter: blur(4px) grayscale(100%);
		-webkit-filter: blur(4px) grayscale(100%);
		-moz-filter: blur(4px) grayscale(100%);
		-o-filter: blur(4px) grayscale(100%);
		opacity: 0.5;
		overflow: hidden;
	}

	.page-title-back {
		position: relative;
		-webkit-background-size: cover !important;
		-moz-background-size: cover !important;
		-o-background-size: cover !important;
		background-size: cover !important;
		background-repeat: no-repeat !important;
		background-position: center top;
		background-attachment: fixed;
		// filter: grayscale(95%);
		// -webkit-filter: grayscale(95%);
		// -moz-filter: grayscale(95%);
		// -o-filter: grayscale(95%);
		max-width: 3200px;
		min-height: 25rem;
		z-index: 2;
	}

	.page-title-banner-overlay {
		background: linear-gradient(180deg, rgba($black,0.80) 0%, rgba($black,0.3) 50%, rgba($black,0) 100%);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 5;
	}

	.page-title-overlay-text-container {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
		display: flex;
		align-items: flex-end;
		.page-title-title-overlay-inner {
			margin: 0;
			padding: 0;
			padding-bottom: 5vw;
			h1.page-title {
				color: rgba($white,0.75);
			}
			a.page-title-backlink {
				z-index: 5;
				color: rgba($white,0.75);
				&:hover {
					color: rgba($white,1);
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: rgba($white,1);
				}
				.fa {
					color: rgba($white,1);
				}
			}
		}
	}
}

.page-title-container-spacer {
	background-color: $gray-dark2;
	min-height: 6.25rem;
}

.page-title-text-container {
	padding: 6.25rem 2.5rem 0.625rem 2.5rem;
	@media (max-width: 575.9px) {
		padding: 3.125rem 1.875rem 0.625rem 1.875rem;
	}
}

.page-title-backlink {
	display: inline-block;
	margin: 0 0 0.625rem 0;
	padding: 0.625rem 0 0.625rem 0;
	font-family: $font-nav1;
	font-size: 0.75em;
	text-transform: uppercase;
	letter-spacing: 0.125em;
	color: $gray-mid2;
	font-weight: 700;
	@media (max-width: 575.9px) {
		margin: 0;
		display: block;
	}
	a {
	}
	.fa, .fac {
		font-size: 0.625rem;
		color: $brandcolor1;
	}
	&:hover {
		color: $brandcolor1;
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark;
		text-decoration: none;
	}
}

h1.page-title {
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;
	line-height: 0.875;
	@media (max-width: 575.9px) {
		margin: 0.625rem 0 0 0;
	}
}
