//Services Callout Cards
.block-services-card-container, 
.services-card-container {
	.block-services-teaser-card, 
	.services-teaser-card {
		position: relative;
		margin-bottom: 2.5rem;
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
		-webkit-perspective: 1000px;
		perspective: 1000px;
		&:hover {
			.img-zoom-hover {
				img {
					transform: scale(1.125);
				}
			}
			.card-front,
			.card-back {
				-webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
				transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
				-o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
				transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
				transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1),
				-webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
				-webkit-transform-style: preserve-3d;
				transform-style: preserve-3d;
			}
			.card-front {
				-webkit-transform: rotateY(-180deg);
				transform: rotateY(-180deg);
			}
			.card-back {
				-webkit-transform: rotateY(0deg);
				transform: rotateY(0deg);
			}
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		}

		.card-front,
		.card-back {
			position: absolute;
			z-index: 1;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			display: block;
			@extend .border-radius-rounded;
			pointer-events: none;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			-webkit-transform-style: inherit;
			transform-style: inherit;
			perspective: inherit;
			-webkit-perspective: inherit;

			-webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
			transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
			-o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
			transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
			transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1),
			-webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);

			box-shadow:
				0px 1.4px 3.9px rgba(0, 0, 0, 0.093),
				0px 3.3px 9.3px rgba(0, 0, 0, 0.133),
				0px 6.1px 17.5px rgba(0, 0, 0, 0.165),
				0px 10.9px 31.3px rgba(0, 0, 0, 0.197),
				0px 20.5px 58.5px rgba(0, 0, 0, 0.237),
				0px 49px 140px rgba(0, 0, 0, 0.33)
			;

      .card-img-overlay {
				position: absolute;
				z-index: 2;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				display: block;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				padding: 0.625rem 0.9375rem;
				background-color: rgba($black, 0.2);
				@extend .border-radius-rounded;
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				-webkit-transform-style: inherit;
				transform-style: inherit;
				perspective: inherit;
				-webkit-perspective: inherit;
				-webkit-transform-style: preserve-3d;
				transform-style: preserve-3d;
				.card-body {
					position: relative;
					z-index: 3;
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					-webkit-transform-style: inherit;
					transform-style: inherit;
					perspective: inherit;
					-webkit-perspective: inherit;
					transform: translateZ(60px) scale(0.94) !important;
				}
			}
    }
		.card-front {
			-webkit-transform: rotateY(0deg);
			transform: rotateY(0deg);						
		}
		.card-back {
			-webkit-transform: rotateY(180deg);
			transform: rotateY(180deg);
			.card-img-overlay {
				.card-body {
					.services-icon-container {
						.services-icon {
							width: 5.0rem;
							height: 5.0rem;
							@media (max-width: 1199.9px) {
								width: 4.0rem;
								height: 4.0rem;
							}
							@media (max-width: 991.9px) {
								width: 6.0rem;
								height: 6.0rem;
							}
							@media (max-width: 767.9px) {
								width: 7.0rem;
								height: 7.0rem;
							}
							@media (max-width: 575.9px) {
								width: 6.0rem;
								height: 6.0rem;
							}
						}
					}
					.services-headline-container {
						.services-headline {
							font-size: 1.5rem;
							@media (max-width: 1199.9px) {
								font-size: 1.25rem;
							}
							@media (max-width: 991.9px) {
								font-size: 1.75rem;
							}
							@media (max-width: 767.9px) {
								font-size: 1.875rem;
							}
							@media (max-width: 575.9px) {
								font-size: 1.875rem;
							}
						}
					}
				}
			}
		}

		.img-zoom-hover {
			@extend .border-radius-rounded;
		}
		.card-img {
			margin: 0;
			padding: 0;
			position: relative;
			@extend .border-radius-rounded;
		}
		.img-placeholder {
			visibility: hidden;
		}
		.card-title-container {
			width: 100%;
			height: calc(100% - 2.0rem);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			justify-self: stretch;
			.services-icon-container {
				.services-icon {
					width: 7.0rem;
					height: 7.0rem;
					@media (max-width: 1199.9px) {
						width: 6.0rem;
						height: 6.0rem;
					}
					@media (max-width: 991.9px) {
						width: 8.0rem;
						height: 8.0rem;
					}
					@media (max-width: 767.9px) {
						width: 9.0rem;
						height: 9.0rem;
					}
					@media (max-width: 575.9px) {
						width: 8.0rem;
						height: 8.0rem;
					}
				}
			}
			.services-headline-container {
				.services-headline {
					color: $white;
					margin-bottom: 0;
					line-height: 1;
					font-size: 1.75rem;
					@media (max-width: 1199.9px) {
						font-size: 1.5rem;
					}
					@media (max-width: 991.9px) {
						font-size: 2.0rem;
					}
					@media (max-width: 767.9px) {
						font-size: 2.125rem;
					}
					@media (max-width: 575.9px) {
						font-size: 2.125rem;
					}
				}
			}
			.services-copy-container {
				padding: 0 2.5rem;
				.services-teaser-copy {
					color: $white;
					line-height: 1.25 !important;
				}
			}
		}

		.card-services-footer {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			a.card-services-learn-more {
				color: $white;
				text-decoration: none;
				.fa, .fac {
					color: $white;
					font-size: 1.75rem;
				}
			}
		}
	}
}

//Services Callouts Block
.block-container-services-callouts {
	.block-inner-container {
	}
}

//Services Callouts in the Services section
.container-services-callouts {
	// background-color: $gray-dark3;
	.inner-container {
		padding: 5.0rem 2.5rem;
		.services-card-row {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}