.upper-chevron-down {
	top: -4.0rem;
	margin-bottom: -4.0rem !important;
	clip-path: polygon(0 0,0 101%,100% 101%,100% 0,50% 3.75rem);
}

.upper-chevron-up {
	top: -4.0rem;
	margin-bottom: -4.0rem !important;
	clip-path: polygon(0 0,0 101%,100% 101%,100% 0,50% -3.75rem);
}