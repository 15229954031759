footer {
	@media (max-width: 575.9px) {
		font-size: 1.0625rem;
	}
}

//Footer Top Rows
.footer-top {
	margin: 0;
	padding: 0;
	background-color: $gray-dark2;
	background-image: url("/assets/images/Backgrounds/Patterns/pattern-topo-map-1-10pct.png");
	background-repeat: repeat;
	background-position: center center;
	background-size: auto;
	background-attachment: fixed !important;
	@supports (-webkit-touch-callout: none) {
		background-attachment: scroll !important;
	}
	.footer-top-inner {
		padding: 7.0em 2.5em;
		@media (max-width: 575.9px) {
			padding: 3.5em 1.875em;
		}
		.footer-info-container {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			@media (max-width: 991.9px) {
				justify-content: center;
			}
			.footer-info-inner-container {
				color: $white;
				font-size: 1.0em;
				line-height: 1;
				text-align: left;
				@media (max-width: 991.9px) {
					font-size: 1.0em;
					margin-bottom: 2.5rem;
					text-align: center;
				}
				@media (max-width: 575.9px) {
					font-size: 1.125em;
				}
				b, strong {
					font-weight: 700;
					color: $white;
				}
				a {
					color: $white;
					&:hover {
						color: $brandcolor1-light;
						text-decoration: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $white;
						text-decoration: none;
					}
				}
				.footer-logo-container {
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0;
					padding: 0;
					.footer-logo {
						display: inline-block;
						background: center center/contain no-repeat;
						text-indent: -9999px;
						margin: 0;
						padding: 0;
						width: 13.875rem;
						height: 5.5rem;
						@media (max-width: 1199.9px) {
							width: 225px;
							height: 87px;
						}
						@media (max-width: 991.9px) {
							width: 200px;
							height: 78px;
						}
						// @media (max-width: 767.9px) {
						// 	margin: 0.625em 0em;
						// 	width: 180px;
						// 	height: 70px;
						// }
						// @media (max-width: 575.9px) {
						// 	width: 125px;
						// 	height: 49px;
						// }
					}
				}
			}
		}
		.footer-text-container {
			@media (max-width: 767.9px) {
				margin-top: 0.625rem;
			}
			.footer-text-inner-container {
				.footer-headline {
					font-family: $font-body1;
					font-size: 1.0rem;
					font-weight: 700;
					color: $brandcolor1;
					margin-bottom: 0;
				}
				.footer-subheadline {
					font-family: $font-body1;
					font-size: 1.0rem;
					font-weight: 700;
					color: $white;
				}
				.footer-bodycopy {
					color: $white;
				}
				.info-phone {
					margin-top: 1.25rem;
					text-wrap: pretty;
					a {
						color: $white;
						font-weight: 700;
						&:hover {
							color: $brandcolor1-light;
							text-decoration: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							color: $white;
							text-decoration: none;
						}
					}
					.fa, .fac {
						color: $brandcolor1;
						margin-right: 0.125rem;
					}
				}
				.info-address {
					margin-top: 1.25rem;
					text-wrap: pretty;
					a {
						color: $white;
						&:hover {
							color: $brandcolor1-light;
							text-decoration: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							color: $white;
							text-decoration: none;
						}
					}
				}
				.info-email {
					margin-top: 1.25rem;
					text-wrap: pretty;
					a {
						color: $white;
						font-weight: 700;
						&:hover {
							color: $brandcolor1-light;
							text-decoration: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							color: $white;
							text-decoration: none;
						}
					}
					.fa, .fac {
						color: $brandcolor1;
						margin-right: 0.125rem;
					}
				}
			}
		}
		.footer-button-container {
			@media (max-width: 991.9px) {
				margin-top: 2.5rem;
			}
			.footer-buttons {
				.btn {
					min-width: 19rem;
					@media (max-width: 719.9px) {
						min-width: auto;
						width: 100%;
					}
				}
				// margin-top: 0.625rem;
			}
		}
	}
}

//Footer Middle Rows
/*
.footer-middle {
	background-color: $gray-dark2;
	margin: 0;
	padding: 0;
	.footer-middle-inner {
		padding: 2.5em 2.5em;
		.footer-affiliations-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			gap: 2.5rem;
			@media (max-width: 767.9px) {
				margin-top: 5.0rem;
				justify-content: center;
				gap: 3.75rem;
			}
		.footer-affiliation {
			@media (max-width: 767.9px) {
				justify-content: center;
			}
			.footer-affiliation-logo {
				max-height: 6.0rem;
				max-width: 16.0rem;
			}
		}
	}
	}
}
*/

// Footer Bottom Rows
.footer-bottom {
	background-color: $brandcolor1;
	margin: 0;
	padding: 0;
	.footer-bottom-inner {
		padding: 1.875rem 2.5rem 3.75rem 2.5rem;
		@media (max-width: 575.9px) {
			padding: 1.875rem 1.875rem 3.75rem 1.875rem;
		}
		text-align: center;
		.footer-copyright {
			color: $white;
			font-size: 0.777rem;
			font-size: clamp(0.777rem, -webkit-calc(0.625rem + 0.25 * ((100vw - 36rem) / 39)), 0.875rem);
			font-size: clamp(0.777rem, -moz-calc(0.625rem + 0.25 * ((100vw - 36rem) / 39)), 0.875rem);
			font-size: clamp(0.777rem, calc(0.625rem + 0.25 * ((100vw - 36rem) / 39)), 0.875rem);
			line-height: 1;
			@media (max-width: 991.9px) {
				padding: 0.625em 2.5em;
			}
			@media (max-width: 767.9px) {
				// font-size: 1.0rem;
				// line-height: 1.5;
			}
		}
		.footer-links {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			color: $white;
			@media (max-width: 991.9px) {
				justify-content: center;
				margin-top: 1.25rem;
				margin-bottom: 1.25rem;
			}
			@media (max-width: 575.9px) { 
				flex-direction: column;
			}
			ul {
				margin: 0;
				padding: 0;
				@media (max-width: 575.9px) {
					margin-bottom: 1.25rem;
					list-style: none;
					width: 100%;
				}
				li.list-inline-item {
					margin: 0;
					padding: 0 0.3125em;
					border-right: 1px solid $gray-dark2;
					@media (max-width: 575.9px) {
						display: block;
						padding: 0.3125em 1.25em;
						border-right: none;
						border-bottom: 1px solid $gray-dark2;
					}
					&:first-child {
						@media (min-width: 575.9px) {
							padding-left: 0;
						}
						@media (max-width: 575.9px) {
							border-top: 1px solid $gray-dark2;
						}
					}
					&:last-child {
						@media (min-width: 575.9px) {
							margin-right: 0.625em;
						}
						@media (max-width: 991.9px) {
							border-right: none;
						}
					}
					a {
						color: $white;
						padding: 0.3125em;
						font-size: 0.777rem;
						font-size: clamp(0.777rem, -webkit-calc(0.625rem + 0.25 * ((100vw - 36rem) / 39)), 0.875rem);
						font-size: clamp(0.777rem, -moz-calc(0.625rem + 0.25 * ((100vw - 36rem) / 39)), 0.875rem);
						font-size: clamp(0.777rem, calc(0.625rem + 0.25 * ((100vw - 36rem) / 39)), 0.875rem);
						line-height: 1;
						&:hover {
							color: $black;
							text-decoration: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							color: $brandcolor1-dark;
							text-decoration: none;
						}
						@media (max-width: 767.9px) {
							font-size: 1.0rem;
							line-height: 1.5;
						}
					}
				}
			}
		}
		.footer-social-icons {
				margin: 0;
				padding: 0;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 0;
				@media (max-width: 991.9px) {
					padding: 0.3125rem 1.25em;
					justify-content: center;
					// gap: 2.0rem;
				}
				.footer-social-icon {
					margin: 0;
					padding: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					color: $white;
					font-size: 2.25em;
					line-height: 1;
					@media (max-width: 991.9px) {
						padding: 0.625rem 2.0rem;
					}
					&:hover {
						color: $gray-dark2;
						text-decoration: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $brandcolor1-dark;
						text-decoration: none;
					}
				}
				.footer-social-desc {
					display: none;
				}
			}
	}
}
