/* General Form Inputs */

input, button, textarea, select {
  font: inherit;
}

.custom-checkbox, .custom-switch {
	.custom-control-input {
		&:checked~.custom-control-label::after {
			background-color: $brandcolor1;
		}
	}
	.custom-control-label {
		margin-top: 0.625rem;
		// padding: 1.25rem 0 0 0;
	}
}

.form-control:focus {
	border-color: $brandcolor1;
	-webkit-box-shadow: inset 0 1px 1px rgba($brandcolor1-light, 0.075), 0 0 0.25em rgba($brandcolor1-light, 0.6);
	-moz-box-shadow: inset 0 1px 1px rgba($brandcolor1-light, 0.075), 0 0 0.25em rgba($brandcolor1-light, 0.6);
	box-shadow: inset 0 1px 1px rgba($brandcolor1-light, 0.075), 0 0 0.25em rgba($brandcolor1-light, 0.6);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

button.active.focus, button.active:focus, button.focus, button.focus:active, button:active:focus, button:focus {
	outline: 0 !important;
	outline-offset: 0  !important;
	background-image: none  !important;
	-webkit-box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.25) !important;
	box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.25)  !important;
}

input[type="text"],
input[type="button"],
input[type="submit"],
input[type="email"],
input[type="tel"],
input[type="number"] {
	// width: 100%;
	padding: 0.625em;
	border: 1px solid $gray-light3 !important;
	border-radius: 0 !important;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	// box-shadow:inset 0 1px 0 $gray-white1;
	// -moz-box-shadow:inset 0 1px 0 $gray-white1;
	// -webkit-box-shadow:inset 0 1px 0 $gray-white1;
}

select {
	padding: 0.125em;
	border: 1px solid $gray-light3;
	border-radius: 0 !important;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	// box-shadow:inset 0 -0.0625em 0 $gray-white2;
	// -moz-box-shadow:inset 0 -0.0625em 0 $gray-white2;
	// -webkit-box-shadow:inset 0 -0.0625em 0 $gray-white2;
	outline: none;
	background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 98% 50%;
	-moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
}

textarea {
	width: 100%;
	padding: 0.625em;
	border: 1px solid $gray-light3 !important;
	border-radius: 0 !important;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	// box-shadow:inset 0 1px 0 $gray-white1;
	// -moz-box-shadow:inset 0 1px 0 $gray-white1;
	// -webkit-box-shadow:inset 0 1px 0 $gray-white1;
}

select.form-control {
	padding: 0.4em 0.625em;
	height: auto !important;
	border: 1px solid $gray-light3;
	border-radius: 0 !important;
}

select.custom-select {
	font-size: 0.875em;
	padding: 0.3125rem 0.125rem;
	border: 1px solid $gray-light3;
	border-radius: 0 !important;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: $gray-light3;
	opacity: 1; /* Firefox */
}
.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: $gray-light3;
}
.form-control::-ms-input-placeholder { /* Microsoft Edge */
	color: $gray-light3;
 }

.form-label-required {
	color: $brandcolor1-dark;
}

.form-check-input:not(:checked), .form-check-input:checked {
	// position: absolute;
	// pointer-events: none;
	// opacity: 0;
}

.form-check-label {
	padding-top: 0.25rem;
}

[type="button"] {
	outline: none;
	box-shadow: none;
	-moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
}

.btn-group-toggle {
	margin: 0 1.25em;
}

.btn-outline-group {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	font-weight: 400 !important;
	text-transform: none !important;
	letter-spacing: 0 !important;
	background-color: none !important;
	color: $gray-dark2 !important;
	border: 1px solid $gray-light3 !important;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1 !important;
		border: 1px solid $gray-light3 !important;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1-light !important;
		border: 1px solid $gray-light3 !important;
		box-shadow: 0 !important;
		outline: none !important;
	}
}

.btn-outline-group:not(:disabled):not(.disabled):active, 
.btn-outline-group:not(:disabled):not(.disabled).active, 
.show>.btn-outline-group.dropdown-toggle {
	color: $white !important;
	background-color: $gray-dark1 !important;
 }

label {
	color: $black;
	font-size: 1.125em;
	font-weight: 400;
	// font-size: 0.875em;
	// text-transform: uppercase;
	// font-weight: 400;
	// letter-spacing: 0.05em;
}

label.required:after  {
 color: $brandcolor1-dark;
}

.form-text {
	padding-top: 0.3125rem;
	font-size: 1.0em;
	font-weight: 400;
}

.form-well {
	margin: 0 0 1.25rem 0;
	padding: 1.25rem;
	background-color: $gray-white0;
	border: 1px solid $gray-white3;
	border-radius: 0;
}
